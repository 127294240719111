
<script setup lang="ts">
import { isMobile } from "o365.GlobalState.ts";
import { useAsyncComponent } from "o365-vue-utils";
import vActionHover from "home.vue.components.ActionHover.ts";


export interface IProps {
    header: string;
    actionId: number;
    href?: string;
    dueDate?: Date;
};

const MCard = useAsyncComponent("o365-mobile/MCard");
const dateNow = new Date();

const parseDate = (date) => {
    return new Date(date);
}

const props = defineProps<IProps>();
</script>

<template>
    <template v-if="isMobile && false">
        <a class="" style="margin: 0 -0.5rem; padding: 0.25rem 0.5rem;" :title="header" :href="href">
            <div>{{ header }}</div>
        </a>
    </template>
    <template v-else-if="isMobile">
        <MCard :header="header" :href="href" shadow="none" />
    </template>
    <template v-else>
        <div class="section-item-container">
            <div class="section-item-link">
                <a :href="href" v-action-hover="actionId" class="section-item-text">
                    <span>{{ header }}</span>
                    <span v-if="props.dueDate" class="section-item-date" :class="{
                        'text-danger': parseDate(props.dueDate) <= dateNow,
                        'text-muted': parseDate(props.dueDate) > dateNow
                    }">
                        ({{ $formatDate(props.dueDate, 'Short Date') }})
                    </span>
                </a>

            </div>
        </div>
    </template>
</template>

<style scoped>
.section-item-container {
    display: block;
    width: 100%;
}

.section-item-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

.section-item-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 0.5rem;
}

.section-item-date {
    flex-shrink: 0;
    white-space: nowrap;
    pointer-events: none;
}
</style>